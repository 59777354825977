@import '~bootstrap/scss/bootstrap';
@import './Palette';

.App {
  font-family: 'Varela Round', 'Helvetica Neue', 'HelveticaNeue', Helvetica,
    Arial, 'Lucida Grande', sans-serif;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.navbar-dark .navbar-brand {
  color: $royal-blue-dark;
  margin: 0;
  align-content: center;
  display: flex;
  flex-grow: 1;
  padding: 2rem;
}

.jumbotron {
  padding: 1rem 10vw;
  margin-bottom: 0;
  text-align: center;
  background-color: transparent;
  opacity: 1;
  @media (min-width: 992px) {
    height: 800px;
    width: 900px;
    padding-left: 98px;
    padding-right: 98px;
  }
}

.hotlinks {
  .list-group {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.125);
    .list-group-item {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
    @media (min-width: 576px) {
      .list-group-item {
        padding-right: 2rem;
        padding-left: 2rem;
      }
    }
  }
}
.hotlinks-container {
  @media (min-width: 992px) {
    width: 900px;
  }
}

.preview {
  background-image: url(/worlds-cover.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  height: 75vw;
  border-radius: 50vw;
  border: #fff 2vw solid;
  opacity: 0.85;
  width: 75vw;
  @media (min-width: 992px) {
    height: 740px;
    width: 740px;
  }
}

.worlds {
  padding-top: 0rem;
  max-width: 740px;
}

.bk1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  opacity: 0.1;
  background: url(/flower.png);
  background-repeat: no-repeat;
  background-size: auto;
  animation: App-logo-spin infinite 60s linear;
}
.bk2 {
  position: fixed;
  top: 4;
  left: 0;
  opacity: 0.2;
  width: 100vh;
  height: 100vh;
  background: url(/sphere.png);
  background-size: auto;
  background-repeat: no-repeat;
  animation: App-logo-spin infinite 70s linear;
}
.bk3 {
  position: fixed;
  top: -60;
  left: 50%;
  opacity: 0.1;
  width: 100vh;
  height: 100vh;
  background: url(/star.png);
  background-size: auto;
  background-repeat: no-repeat;
  animation: App-logo-spin infinite 80s linear;
}
.bk4 {
  position: fixed;
  top: 50%;
  left: 20%;
  opacity: 0.1;
  height: 100vh;
  width: 100vh;
  background: url(/star.png);
  background-size: auto;
  background-repeat: no-repeat;
  animation: App-logo-spin2 infinite 65s;
}
.bk5 {
  position: fixed;
  top: 300;
  left: 0;
  opacity: 0.1;
  height: 100vh;
  width: 100vh;
  background: url(/orange.png);
  background-size: auto;
  background-repeat: no-repeat;
  animation: App-logo-spin2 infinite 95s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.navbar {
  .navbar-brand {
  }
}
