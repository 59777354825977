@import '~bootstrap/scss/bootstrap';
@import './Palette';

.card.album {
  background-color: $omni-gray1;
  margin-bottom: 1rem;

  & .card-title {
    padding: 1rem 0;
    color: $royal-blue-dark;
  }

  & img {
    max-width: 300px;
    max-height: 300px;
    padding: 0;
    margin: auto;
    border-radius: 0.25rem;
  }

  & .list-group {
  }

  & .list-group-item {
    border-top: 0;
    border-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  & .list-group-item:last-child {
  }
}
